<template>
  <div>
    <modal-edit-meetingtypes
      v-if="showEditMeetingtypes"
      :showModal="showEditMeetingtypes"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditMeetingtypes">
      <template v-slot:title>Meeting types</template>
    </ui-section-header>

    <message
      :message="'Specify on what type of booking the option is available'"
    />

    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Active</th>
          <th>Meetingtype</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="meetingtype in meetingtypes"
          :key="meetingtype.MeetingtypeId"
        >
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="checkOptionOnMeetingtype(meetingtype.MeetingtypeId)"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!checkOptionOnMeetingtype(meetingtype.MeetingtypeId)"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td>
            <span>{{ meetingtype.MeetingtypeId | getMeetingtypeName }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditOptionMeetingtypes = () =>
  import('@/components/Options/ModalEditOptionMeetingtypes')

export default {
  components: {
    message: Message,
    'modal-edit-meetingtypes': ModalEditOptionMeetingtypes,
  },

  props: {},

  data() {
    return {
      showEditMeetingtypes: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    meetingtypes() {
      if (this.locationState) {
        return this.locationState.Meetingtypes
      } else {
        let meetingtypes = []

        if (this.channel) {
          for (let i = 0; i < this.channel.Meetingtypes.length; i++) {
            let mt = this.channel.Meetingtypes[i]

            meetingtypes.push({
              MeetingtypeId: mt.MeetingtypeId,
            })
          }
        }
        return meetingtypes
      }
    },
  },

  created() {},

  methods: {
    checkOptionOnMeetingtype(meetingtypeId) {
      let result = false

      let mtIndex = this.option.MeetingtypeIds.findIndex(
        (m) => m === meetingtypeId
      )
      if (mtIndex > -1) {
        result = true
      }

      return result
    },

    setShowEditMeetingtypes() {
      this.showEditMeetingtypes = true
    },

    hideModal() {
      this.showEditMeetingtypes = false
    },
  },
}
</script>

<style></style>
